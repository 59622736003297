





























































/**
 * @Author mcastrucci
 * Admin Comments Modal.
 * This modal contains the Admin comments from a requisition. It allows to view, add and remove comments.
 */
import {Component, Prop, Vue} from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import TestResultComments from '@/components/TestResultComments.vue';

@Component({components: {TestResultComments}})
export default class AdminCommentsModal extends AppComponent {
  @Prop({type: Object, default: {criticalReviewed: false, hasCritical: false, comments: []}})
  public opts: {
    criticalReviewed: boolean;
    hasCritical: boolean;
    comments: {comment: string; updatedAt: string | Date};
  };
  public newPhysicianComment = '';
  public dialog = false;
  public criticalFlag = false;

  public physicianCommentRemoved(item) {
    this.$emit('admin-comments:removed', item);
    this.newPhysicianComment = '';
  }

  public physicianCommentUpdated(original, updated) {
    this.$emit('admin-comments:updated', original, updated);
    this.newPhysicianComment = '';
  }

  public createPhysicianComment() {
    this.$emit('admin-comments:created', this.newPhysicianComment);
    this.newPhysicianComment = '';
  }

  public reviewCriticalResults() {
    this.$emit('admin-comments:review-critical-results', {
      comment: this.newPhysicianComment,
      criticalReviewed: true,
    });
    this.newPhysicianComment = '';
  }

  public cancel() {
    this.dialog = false;
  }
}
