var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"calc(100% - 57px)","overflow":"hidden"}},[(_vm.$route.name === 'physician-results-step')?[_c('div',{staticClass:"results-steps-content"},[(_vm.fetchingRequisition)?_c('v-skeleton-loader',{staticClass:"mt-2",attrs:{"type":"table"}}):_vm._e(),(!_vm.fetchingRequisition && !!_vm.requisitionOnView)?_c('v-flex',{staticClass:"pb-6 physician-results-view",attrs:{"sm12":""}},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panelIndex),callback:function ($$v) {_vm.panelIndex=$$v},expression:"panelIndex"}},[_c('v-expansion-panel',{staticClass:"mt-2 mb-1 py-2"},[_c('v-expansion-panel-header',{staticClass:"headline primary--text"},[_vm._v(" Patient Data ")]),_c('v-expansion-panel-content',[(_vm.requisitionOnView.patient)?_c('PatientData',{attrs:{"noTitle":"","priority":_vm.priority && _vm.priority > 0,"patient":_vm.requisitionOnView.patient}}):_vm._e()],1)],1),_c('v-expansion-panel',{staticClass:"mt-2 mb-1 py-2"},[_c('v-expansion-panel-header',{staticClass:"headline primary--text"},[_vm._v(" Test results ")]),_c('v-expansion-panel-content',[_c('TestResultsTable',{attrs:{"loading":_vm.tableLoading,"priority":_vm.priority,"testResults":_vm.testResults}})],1)],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('div',{staticClass:"d-flex pb-2 pt-4 justify-space-between"},[_c('v-btn',{attrs:{"to":"/main/physician/results/"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-arrow-left")]),_vm._v(" Back to all requisitions ")],1),_c('AdminCommentsModal',{attrs:{"opts":_vm.getOptsForAdminCommentsModal},on:{"admin-comments:removed":_vm.removeComment,"admin-comments:updated":_vm.commentUpdated,"admin-comments:created":_vm.createComment,"admin-comments:review-critical-results":_vm.reviewCriticalResult}}),(
          _vm.requisitionOnView &&
          _vm.requisitionOnView.visits &&
            _vm.requisitionOnView.visits.every(
              function (visit) { return !visit.criticalResult ||
                (visit.criticalResult && visit.criticalResult.criticalReviewed); }
            )
        )?_c('v-btn',{attrs:{"to":("/main/physician/results/" + (_vm.$route.params.requisitionId) + "/review"),"color":"primary"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-pencil")]),_vm._v(" Write review ")],1):_vm._e()],1)]:_vm._e(),_c('router-view',{attrs:{"requisitionOnView":_vm.requisitionOnView,"testResults":_vm.testResults}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }