










































































import AppComponent from '@/mixins/ComponentMixin.vue';
import {Component, Watch} from 'vue-property-decorator';
import TestResultItem from '@/components/TestResultItem.vue';
import {
  dispatchCreateRequisitionComment, dispatchCreateReviewingTime,
  dispatchDeleteRequisitionComment,
  dispatchGetRequisitionResultsById,
  dispatchUpdateCriticalResult,
  dispatchUpdateRequisitionComment,
} from '@/store/crud/actions';
import {ICreatePhysicianComment, IRequisition, ITestResult} from '@/interfaces/requisitions';
import PatientData from '@/components/PatientData.vue';
import TestResultsTable from '@/components/tables/TestResultsTable.vue';
import {
  readHasNurseAccess,
  readHasPhysicianAccess,
  readHasReviewerAcccess,
  readUserProfile,
} from '@/store/main/getters';
import {IBiomarkerResult} from '@/interfaces/biomarkerResults';
import {IVisit} from '@/interfaces/visits';
import AdminCommentsModal from '@/components/modals/AdminCommentsModal.vue';
import {ICriticalResult} from '@/interfaces/criticals';
import {IRequisitionComment, IRequisitionCommentInput} from '@/interfaces/requisitionComments';
import {ITimeTracker} from '@/interfaces/timeTracker';
import moment from 'moment/moment';

@Component({
  components: {TestResultsTable, PatientData, TestResultItem, AdminCommentsModal},
})
export default class PhysicianResults extends AppComponent {
  public get isNurse() {
    return readHasNurseAccess(this.$store);
  }

  public get userProfile() {
    return readUserProfile(this.$store);
  }

  public get isPhysician() {
    return readHasPhysicianAccess(this.$store);
  }

  public get isReviewer() {
    return readHasReviewerAcccess(this.$store);
  }

  public get priority() {
    let priority = 0;
    // check if there is critical values
    if (this.testResults.some((result) => result.biomarkerCritical && result.resultCritical)) {
      // check if there is any priority 1 critical
      if (
        this.testResults.some(
          (result) =>
            result.biomarkerCritical && result.isOutOfRangePriority1 && result.isPriority1,
        )
      ) {
        priority = 1;
      } else {
        priority = 2;
      }
    } else {
      return false;
    }
    return priority;
  }

  public get getOptsForAdminCommentsModal() {
    const comments: IRequisitionComment[] = [];
    let criticalReviewed = false;
    let hasCritical = false;

    if (this.nextVisitCriticalResult) {
      hasCritical = true;
      criticalReviewed = this.nextVisitCriticalResult.criticalReviewed;
    }

    if (this.requisitionOnView && this.requisitionOnView.requisitionComments.length > 0) {
      comments.push(...this.requisitionOnView.requisitionComments);
    }

    comments.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateA.getTime() - dateB.getTime();
    });

    return {criticalReviewed, comments, hasCritical};
  }



  public fetchingRequisition = false;
  public requisitionOnView: IRequisition | null = null;
  public nextVisitCriticalResult: ICriticalResult | null | undefined = null;
  public testResults: ITestResult[] = [];
  public panelIndex: number[] = [0, 1, 2];
  public tableLoading: boolean = false;
  public comments: string = '';
  public disabled: boolean = false;
  public time = 10000;

  public async fetchRequisition() {
    const selectedRequisitionId = this.$route.params?.requisitionId;
    if (selectedRequisitionId) {
      this.fetchingRequisition = true;
      await this.$nextTick();
      const result = await dispatchGetRequisitionResultsById(this.$store, selectedRequisitionId);
      if (result) {
        this.requisitionOnView = result;
        // check if there is a visit with critical results that has not been reviewed
        // sort them by critical / critical level and reviewed flag.
        const temp = this.requisitionOnView?.visits.filter((visit) => {
          return visit.criticalResult && !visit.criticalResult.criticalReviewed;
        });
        // Even if there is more than one critical result, we only need the first one
        if (temp && temp.length > 0) {
          this.nextVisitCriticalResult = temp[0].criticalResult;
          this.disabled =
            !!this.nextVisitCriticalResult && this.nextVisitCriticalResult.criticalReviewed;
        }
      } else {
        this.toast(`Error while fetching requisition ${selectedRequisitionId}`, true);
      }
      this.fetchingRequisition = false;
    }
  }

  public created() {
    this.fetchRequisition();
    this.startTimer();
  }

  @Watch('requisitionOnView', {immediate: true})
  public onTestResultChanged(newValue: IRequisition, oldValue: IRequisition) {
    this.configureScreen();
  }

  @Watch('requisitionOnView.physicianNotes', {immediate: true})
  public onTestResultCommentsChanged(newValue: ICreatePhysicianComment[]) {
    if (newValue && newValue.length > 0) {
      this.panelIndex = [...this.panelIndex, 2];
    }
  }

  /**
   * fetchs data asyncrhonously and
   * digests data. It show a loading spinner
   */
  public async configureScreen() {
    try {
      this.tableLoading = true;
      await this.$nextTick();
      await this.processData();
    } catch (ex) {
      this.toast('failed while configuring biomarker results', true);
      this.$router.back();
    } finally {
      this.tableLoading = false;
    }
  }

  /**
   * digests the testResults into a more friendly and readable format
   */
  public async processData() {
    this.testResults = [];
    const biomarkerResultsFromVisits: IBiomarkerResult[] = [];
    if (
      this.requisitionOnView &&
      this.requisitionOnView.visits &&
      this.requisitionOnView.visits.length > 0
    ) {
      // get all biomarker results from all visits
      for (const visit of this.requisitionOnView.visits as IVisit[]) {
        if (
          visit.biomarkerResults &&
          Array.isArray(visit.biomarkerResults) &&
          visit.biomarkerResults.length > 0
        ) {
          biomarkerResultsFromVisits.push(...(visit.biomarkerResults as IBiomarkerResult[]));
        } else if (
          visit.biomarkerResults &&
          !Array.isArray(visit.biomarkerResults) &&
          Object.keys(visit.biomarkerResults).length > 0
        ) {
          biomarkerResultsFromVisits.push(visit.biomarkerResults as IBiomarkerResult);
        }
      }

      if (biomarkerResultsFromVisits && biomarkerResultsFromVisits.length > 0) {
        biomarkerResultsFromVisits.forEach((bResult) => {
          this.createTestResultItem(bResult);
        });
      }
    }
    return Promise.resolve();
  }

  /**
   * creates a test Result for each item in a friendly format
   * Each Biomarker should have sexSpecific information that should contain a "critical" structure.
   * This critical structure contains two flags: "isPriority1" and "isPriority2", if one of these flags
   * are true, the biomarker is considered critical.
   * also each result has a flag to indicate that the result is out of range because it exceeds the
   * normal values.
   */
  public createTestResultItem(item: IBiomarkerResult) {
    const expandedResult = {} as ITestResult;

    if (item.biomarker && item.biomarker.id) {
      const patientBiologicalSex = this.getPatientBiologicalSexFromRequisition(
        this.requisitionOnView as IRequisition,
      );

      const biomarkerSexDetailsForPatient = this.getBiomarkerSexDetailsForPatientBiologicalSex(
        item.biomarker,
        patientBiologicalSex,
      );

      const currentBiomarkerIsCritical = this.checkIfBiomarkerIsCritical(
        item.biomarker,
        patientBiologicalSex,
      );
      expandedResult.biomarkerCritical = currentBiomarkerIsCritical;

      expandedResult.biomarkerCode = item.biomarker.questBiomarkerCode || '';
      expandedResult.biomarkerName = item.biomarker.name || '';
      // optimal ranges calculations
      if (biomarkerSexDetailsForPatient && Object.keys(biomarkerSexDetailsForPatient).length > 0) {
        if (
          biomarkerSexDetailsForPatient.optimalRangeHigh ||
          biomarkerSexDetailsForPatient.optimalRangeLow
        ) {
          if (
            biomarkerSexDetailsForPatient.optimalRangeHigh &&
            biomarkerSexDetailsForPatient.optimalRangeLow
          ) {
            expandedResult.optimalRanges = `${biomarkerSexDetailsForPatient.optimalRangeLow} - ${biomarkerSexDetailsForPatient.optimalRangeHigh}`;
          } else if (biomarkerSexDetailsForPatient.optimalRangeHigh) {
            expandedResult.optimalRanges = `${biomarkerSexDetailsForPatient.optimalRangeHigh}`;
          } else if (biomarkerSexDetailsForPatient.optimalRangeLow) {
            expandedResult.optimalRanges = `${biomarkerSexDetailsForPatient.optimalRangeLow}`;
          }
        } else {
          expandedResult.optimalRanges = '';
        }

        if (currentBiomarkerIsCritical) {
          expandedResult.isPriority1 = biomarkerSexDetailsForPatient.critical!.isPriority1
            ? true
            : false;
          expandedResult.isPriority2 = biomarkerSexDetailsForPatient.critical!.isPriority2
            ? true
            : false;
          expandedResult.priority1Range =
            biomarkerSexDetailsForPatient.critical!.priority1Range || '';
          expandedResult.priority2Range =
            biomarkerSexDetailsForPatient.critical!.priority2Range || '';

          /* Calculate if results are out of range */
          expandedResult.isOutOfRangePriority1 = expandedResult.isPriority1
            ? this.calculateOutOfRangeResult(expandedResult.priority1Range, item.testResult)
            : false;

          expandedResult.isOutOfRangePriority2 = expandedResult.isPriority2
            ? this.calculateOutOfRangeResult(expandedResult.priority2Range, item.testResult)
            : false;

          /**
           * Result is critical when biomarker is marked as critical
           * and is priority 1 or 2, also it should be out of range according to the resunt and priority ranges
           */
          if (currentBiomarkerIsCritical) {
            expandedResult.resultCritical =
              expandedResult.isOutOfRangePriority2 || expandedResult.isOutOfRangePriority1;
          }
        }
      }
    }

    expandedResult.biomarkerResult = (item.testResult as string) || '';
    expandedResult.biomarkerUnit = item.measurementUnits || '';
    expandedResult.isOutOfRange = item.testResultOutOfRange ? true : false;
    expandedResult.range = item.questReferenceRange || '';

    this.testResults.push(expandedResult);
  }

  /**
   * removes a comment from the array of comments
   */
  public async removeComment(item) {
    if (!this.requisitionOnView) {
      return;
    }
    if (!item) {
      return;
    }
    this.setAppLoading(true);
    let result: boolean = false;
    result = await dispatchDeleteRequisitionComment(this.$store, {id: item.id});
    this.setAppLoading(false);

    if (result) {
      this.toast('Comment deleted!', false);
      if (this.requisitionOnView) {
        const toDeleteIndex = this.requisitionOnView.requisitionComments.findIndex(
          (i) => i.id === item.id,
        );
        if (toDeleteIndex !== -1) {
          this.requisitionOnView.requisitionComments.splice(toDeleteIndex, 1);
        }
      }
    } else {
      this.toast('error deleting comment', true);
    }
  }

  public async createComment(comment: string) {
    const resultComments = {} as IRequisitionCommentInput;
    if (!this.comments && !comment) {
      this.toast('Please enter a comment', true);
      return;
    }

    resultComments.comment = comment;
    resultComments.requisitionId = String(this.requisitionOnView?.id);

    this.setAppLoading(true);

    const result = await dispatchCreateRequisitionComment(this.$store, resultComments);

    this.setAppLoading(false);

    if (result) {
      this.toast('Comment created!', false);
      if (this.requisitionOnView?.requisitionComments) {
        this.requisitionOnView?.requisitionComments.push(result.createRequisitionComment);
      }
    } else {
      this.toast('error creating comment', true);
    }

    // emit that a new comment was created
    this.$emit('commentCreated', resultComments);
    // update the requisition on view
    return Promise.resolve(resultComments);
  }

  public async reviewCriticalResult(
    opts: {comment: string; criticalReviewed: boolean} = {comment: '', criticalReviewed: false},
  ) {
    const commentsResult = await this.createComment(opts.comment);
    if (commentsResult) {
      try {
        await this.toggleCriticalResultReview(opts.criticalReviewed);
        if (this.requisitionOnView?.id) await this.trackTimes(this.requisitionOnView?.id, 'Critical');
        this.toast('Critical Value Actioned, Saved!', false);
      } catch (ex) {
        this.toast('error while changing result critical to reviewed', true);
      }
    } else {
      this.toast('error while creating critical review comment', true);
    }
  }

  public async toggleCriticalResultReview(value) {
    if (!this.requisitionOnView || !this.nextVisitCriticalResult) {
      return;
    }
    this.setAppLoading(true);

    let result: boolean = false;

    for (const visit of this.requisitionOnView.visits) {
      if (visit.criticalResult && visit.criticalResult.criticalReviewed !== value) {
        result = await dispatchUpdateCriticalResult(this.$store, {
          id: visit.criticalResult.id,
          criticalReviewed: value,
        });
      }
    }

    this.setAppLoading(false);

    if (result) {
      this.toast('Patient was notified of critical results saved!', false);
      this.nextVisitCriticalResult.criticalReviewed = value;
      this.$router.push('/main/physician/results');
    } else {
      this.toast('error saving critical results reviewed', true);
    }
  }

  public async commentUpdated(comment, updatedCommentValue) {
    if (!this.requisitionOnView) {
      return;
    }
    this.setAppLoading(true);

    let result: boolean = false;
    result = await dispatchUpdateRequisitionComment(this.$store, {
      id: comment.id,
      comment: updatedCommentValue,
    });

    this.setAppLoading(false);

    if (result) {
      this.toast('Comment saved!', false);
      if (this.requisitionOnView) {
        const toUpdateIndex = this.requisitionOnView.requisitionComments.findIndex(
          (i) => i.id === comment.id,
        );
        if (toUpdateIndex !== -1) {
          this.requisitionOnView.requisitionComments[toUpdateIndex].comment = updatedCommentValue;
        }
      }
    } else {
      this.toast('error saving comment', true);
    }
  }
}
